package info.rezident.app.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.Input
import com.varabyte.kobweb.silk.components.forms.InputSize
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.firestore.firestore
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HelloPage() {
    Column(Modifier.fillMaxSize().padding(1.cssRem)) {
        Div(Modifier.align(Alignment.Start).toAttrs()) {
            Link("/") {
                // Block display overrides inline display of the <img> tag, so it calculates centering better
                val colorMode = ColorMode.current
                Image(
                    if (colorMode.isLight) {
                        "/logo-header-light.png"
                    } else {
                        "/logo-header-dark.png"
                    },
                    "rezident.info Logo",
                    Modifier
                        .height(3.cssRem)
                        .display(DisplayStyle.Block)
                )
            }
        }

        Div(Modifier.height(2.cssRem).toAttrs())

        var emailAddress by remember { mutableStateOf("") }
        var emailSent by remember { mutableStateOf(false) }
        LaunchedEffect(emailSent) {
            if (emailSent) {
                val firestore = Firebase.firestore
                firestore.collection("emails").add(mapOf("e-mail" to emailAddress))
                emailSent = true

                val analytics = Firebase.analytics
                analytics.logEvent("email-sent", mapOf("isOz" to true))
            }
        }

        if (emailSent) {
            H1 {
                Text("✅ Îți mulțumim pentru interes!")
                Div()
                Text("Revenim către tine în cel mai scurt timp.")
            }
        } else {
            H1 {
                Text("Salut!👋")
                Div()
                Text("Hai să ne cunoaștem, lasă-ne un contact pentru a începe.")
            }

            Div(Modifier.height(0.5.cssRem).toAttrs())

            Input(
                InputType.Text,
                emailAddress,
                onValueChange = {
                    emailAddress = it
                },
                placeholder = "Adresa ta de e-mail",
                size = InputSize.LG,
            )

            Div(Modifier.height(16.px).toAttrs())

            Button(
                onClick = {
                    emailSent = true
                },
                enabled = emailAddress.isNotBlank()
            ) {
                Text("Salvează")
            }
        }

        Div(Modifier.height(2.cssRem).toAttrs())

        val helloCollection = Firebase.firestore.collection("hello").snapshots
        var helloText by remember { mutableStateOf("") }
        LaunchedEffect(Unit) {
            helloCollection
                .onEach { snapshot ->
                    helloText = snapshot.documents.firstOrNull()?.get("text") ?: ""
                }
                .launchIn(this)
        }

        H3 {
            Text(helloText)
        }
    }
}