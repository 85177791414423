package info.rezident.app.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import info.rezident.app.HeadlineTextStyle
import info.rezident.app.components.sections.Footer
import info.rezident.app.components.sections.NavHeader
import info.rezident.app.toSitePalette
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle = CssStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle = CssStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}

//@Page
//@Composable
//fun HomePage() {
////    PageLayout("Home") {
//    document.title = "Specializarea care ți se potrivește"
//
//    Row(HeroContainerStyle.toModifier()) {
//        Box(Modifier.backgroundColor(Colors.GreenYellow)) {
//            val sitePalette = ColorMode.current.toSitePalette()
//
//            Column(Modifier.gap(2.cssRem).width(200.px)) {
//                Div(HeadlineTextStyle.toAttrs()) {
//                    SpanText(
//                        "Alege în 2 minute specializarea la ",
//                        Modifier
//                            .color(
//                                when (ColorMode.current) {
//                                    ColorMode.LIGHT -> Colors.Black
//                                    ColorMode.DARK -> Colors.White
//                                }
//                            )
//                    )
//                    SpanText(
//                        "Rezi",
//                        Modifier
//                            .color(sitePalette.brand.accent)
//                            // Use a shadow so this light-colored word is more visible in light mode
//                            .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)
//                    )
//                }
//
//                Div(SubheadlineTextStyle.toAttrs()) {
//                    SpanText("You can read the ")
//                    Link("/about", "About")
//                    SpanText(" page for more information.")
//                }
//
//                val ctx = rememberPageContext()
//                val analytics = Firebase.analytics
//                Button(onClick = {
//                    ctx.router.tryRoutingTo("/hello")
//                    analytics.logEvent("test-hello")
//                    // Change this click handler with your call-to-action behavior
//                    // here. Link to an order page? Open a calendar UI? Play a movie?
//                    // Up to you!
//                }, colorScheme = ColorSchemes.Blue) {
//                    Text("This could be your CTA")
//                }
//            }
//        }

//        Image("/hero.png", "Viitori medici rezidenți", width = 600)

//            Div(HomeGridStyle
//                .toModifier()
//                .displayIfAtLeast(Breakpoint.MD)
//                .grid {
//                    rows { repeat(3) { size(1.fr) } }
//                    columns { repeat(5) {size(1.fr) } }
//                }
//                .toAttrs()
//            ) {
//                val sitePalette = ColorMode.current.toSitePalette()
//                GridCell(sitePalette.brand.primary, 1, 1, 2, 2)
//                GridCell(ColorSchemes.Monochrome._600, 1, 3)
//                GridCell(ColorSchemes.Monochrome._100, 1, 4, width = 2)
//                GridCell(sitePalette.brand.accent, 2, 3, width = 2)
//                GridCell(ColorSchemes.Monochrome._300, 2, 5)
//                GridCell(ColorSchemes.Monochrome._800, 3, 1, width = 5)
//            }
//    }
//    }
//}

@Page
@Composable
fun HomePage() {
    LaunchedEffect(Unit) {
        document.title = "Specializarea potrivită ție 🩺"
    }

    Box(Modifier.fillMaxSize()) {
        Div(Modifier.fillMaxWidth().align(Alignment.TopCenter).toAttrs()) {
            NavHeader()
        }

        Column(
            Modifier.align(Alignment.Center),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Div(Modifier.weight(1).toAttrs())

                val sitePalette = ColorMode.current.toSitePalette()
                Column(Modifier.gap(2.cssRem).padding(16.px)) {
                    Div(HeadlineTextStyle.toAttrs()) {
                        SpanText(
                            "Alege în 2 minute\nspecializarea la ",
                            Modifier
                                .color(
                                    when (ColorMode.current) {
                                        ColorMode.LIGHT -> Colors.Black
                                        ColorMode.DARK -> Colors.White
                                    }
                                )
                        )
                        SpanText(
                            "Rezi",
                            Modifier
                                .color(sitePalette.brand.accent)
                                // Use a shadow so this light-colored word is more visible in light mode
                                .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)
                        )
                    }

                    val ctx = rememberPageContext()
                    val analytics = Firebase.analytics
                    Button(
                        onClick = {
                            ctx.router.tryRoutingTo("/hello")
                            analytics.logEvent("landing-cta-click")
                            // Change this click handler with your call-to-action behavior
                            // here. Link to an order page? Open a calendar UI? Play a movie?
                            // Up to you!
                        },
                        Modifier.fontSize(FontSize.XXLarge),
                        size = ButtonSize.LG,
                        colorScheme = ColorSchemes.Orange,
                    ) {
                        SpanText("Să începem", Modifier.fontSize(2.25.cssRem))
                    }
                }

                Div(Modifier.weight(1).toAttrs())

                Image(
                    "/hero.webp",
                    "Viitori medici rezidenți",
                    Modifier
                        .weight(1)
                        .fillMaxWidth(50.percent)
                        .displayIfAtLeast(Breakpoint.MD)
                )
            }
        }

        Footer(Modifier.fillMaxWidth().align(Alignment.BottomCenter))
    }
}